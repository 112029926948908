import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Fab, TextField } from '@mui/material';
import { format } from 'date-fns';
import { Box, Stack, width } from '@mui/system';
import DatePicker from './date-picker';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface DatePickerRangeProps {
    readonly dates: { start: string | null, end: string | null }
    readonly onChangeDate: (date: any) => void
    readonly onClickFab: (event: any) => void
}

const DatePickerRange: FC<DatePickerRangeProps> = ({ dates, onChangeDate, onClickFab}) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems='center'
            width='100%'
        >
            <DatePicker
                label={'Inicio'}
                date={dates['start']}
                setDate={(date: string) => onChangeDate((prevState: any) => ({ ...prevState, 'start': date }))}
                sx={{width: '45%'}}
            />
            <DatePicker
                label={'Final'}
                date={dates['end']}
                setDate={(date: string) => onChangeDate((prevState: any) => ({ ...prevState, 'end': date }))}
                inputProps={{
                    min: dates['start'],
                }}
                sx={{width: '45%'}}
            />
            <Box
                sx={{
                    display: 'flex',
                    margin: 'auto',
                    height: '100%',
                    width: '10%'
                }}
            >
                <Fab
                    color="primary"
                    aria-label="edit"
                    size='small'
                    onClick={onClickFab}
                    sx={{
                        width: '24px',
                        height: '24px',
                        minHeight: '24px',
                    }} >
                    <KeyboardArrowRightIcon />
                </Fab>
            </Box>
        </Stack>
    )
}


export default DatePickerRange;