import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Button, Checkbox, OutlinedInput, Rating } from '@mui/material';
import ModalMatchItemOpportunity from './modal-match-item-opportunity';
import StarIcon from '@mui/icons-material/Star';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useStoreActions, useStoreState } from '../store';
import parseCurrency from '../utils/parseCurrency';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: 'red',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface OpportunityItemsListTableProps {
    count?: number;
    items?: Array<any>;
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string }>;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: Product[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const OpportunityItemsListTable: FC<OpportunityItemsListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 10,
        headers = []
    } = props;

    const setItemsToOffer = useStoreActions((actions: any) => actions.opportunityDetail.setItemsToOffer);
    const setSellingPrice = useStoreActions((actions: any) => actions.opportunityDetail.setSellingPrice);
    const sellingPrice = useStoreState((actions: any) => actions.opportunityDetail.sellingPrice);

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('id');
    const [isModalOpen, setIsModelOpen] = useState<boolean>(false);
    const [itemDetailIndex, setItemDetailIndex] = useState<number | null>(null);

    const toggleModal = (currentItemIndex: number) => {
        setIsModelOpen(!isModalOpen);
        setItemDetailIndex(currentItemIndex)
    }

    const handleClose = () => {
        setIsModelOpen(false);
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAddItemsToOffer = (data: Record<string, string>, isChecked: boolean) => {
        setItemsToOffer({ item: data, isChecked });
    }

    const visibleRows = useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)),
        [order, orderBy, items],
    );

    return (
        <Box>
            <ModalMatchItemOpportunity isOpen={isModalOpen} handleClose={handleClose} itemDetailIndex={itemDetailIndex} />
            <Table
                sx={{
                    // minWidth: 750,
                    border: '1px solid #f0f0f0',
                }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {
                        visibleRows.map((row: Record<string, any>, index) => <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                        >
                            {
                                row["ItemName_solicitado"] && <>
                                    <TableCell>
                                        <Checkbox
                                            aria-label='checkbox_ofertar_lineas'
                                            disableRipple
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddItemsToOffer(row, event.target.checked)}
                                            checked={row["offered"]}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button variant="text" size='small' startIcon={<EditNoteIcon />} onClick={() => toggleModal(row['index'])}>
                                            Editar
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">{row["ItemCode_solicitado"] ? row["ItemCode_solicitado"] : '-'}</TableCell>
                                    <TableCell align="left">{row["ItemName_solicitado"]}</TableCell>
                                    <TableCell align="left">{row["ItemName_match_sugerido"]}</TableCell>
                                    <TableCell align="left">{row["ItemCode_match_sugerido"]}</TableCell>
                                    <TableCell align="left"> {row["nombre_proveedor_match"]} </TableCell>
                                    <TableCell align="left"> {row["categoria_rotacion"]} </TableCell>
                                    <TableCell align="left">{row["cantidad_solicitada"]}</TableCell>
                                    <TableCell align="left">{row["Available_clds"]}</TableCell>

                                    <TableCell align="left">
                                        {
                                            row['cluster_match_sugerido'] !== null ?
                                                <Rating
                                                    name="text-feedback"
                                                    value={parseInt(row['cluster_match_sugerido'].toString()) + 1}
                                                    readOnly
                                                    precision={1}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                    max={5}
                                                /> :
                                                'Manual'
                                        }
                                    </TableCell>
                                    <TableCell align="left">{row['similitud_coseno_match_sugerido'] !== null ? (100 * parseFloat(row['similitud_coseno_match_sugerido'].toString())).toFixed(2) + '%' : 'Manual'}</TableCell>
                                    <TableCell align="left">
                                        {
                                            row["estimated_cost_match_sugerido"] !== null ? parseCurrency(row["estimated_cost_match_sugerido"]) : '-'
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        <OutlinedInput
                                            onChange={(event: any) => setSellingPrice({ detail_item: row, value: event.target.value })}
                                            value={sellingPrice[row['index']] ? sellingPrice[row['index']]['value'] || '' : parseFloat(row["selling_price_match_sugerido"]) || ''}
                                            id={`selling_price-${row['index']}`}
                                            name={`selling_price-${row['index']}`}
                                            type="number"
                                            size='small'
                                            inputProps={{ inputMode: 'numeric', min: 0 }}
                                            sx={
                                                {
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '5px',
                                                    },
                                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                        display: 'none'
                                                    },
                                                    '& input[type=number]': {
                                                        MozAppearance: 'textfield'
                                                    },
                                                }
                                            }
                                        />
                                    </TableCell>
                                </>
                            }

                        </TableRow>
                        )
                    }
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

OpportunityItemsListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
