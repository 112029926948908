import type { ChangeEvent, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import type { Product } from '../types/product';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import ProductImage from './product-image';

import Tooltip from '@mui/material/Tooltip';


interface ProductListGridProps {
    count?: number;
    items?: Product[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
}

interface ProductCardProps {
    readonly product: Product
}

interface CardDescriptionProps {
    readonly title: string
    readonly subtitle: string
}

const CardDescription: FC<CardDescriptionProps> = ({ title, subtitle }) => {
    return <Stack direction='row' spacing={1}>
        <Typography variant="body2">
            {title}:
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {subtitle}
        </Typography>
    </Stack>
}

const ProductCard: FC<ProductCardProps> = ({ product }) => {

    return (
        <Card
            sx={{
                width: 340,
                minHeight: 500,
                backgroundColor: 'white',
            }}
            key={product.ItemCode}
        >
            <CardActionArea
                sx={{
                    textDecoration: 'none',
                    minHeight: 500
                }}
                component={Link}
                to={`/detalle-producto?ItemCode=${product.ItemCode}`}
            >
                <CardMedia>
                    <ProductImage ItemCode={product.ItemCode} width={'340px'} height={'340px'} link_imagen={product.link_imagen} />
                </CardMedia>
                <Tooltip title="NA">
                    <Badge
                        badgeContent={'Stock ' + product.Available_clds}
                        color={product.Available_clds > 0 ? "success" : "error"}
                        showZero
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{ width: '90%' }}
                    />
                </Tooltip>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {product.ItemName}
                    </Typography>
                    <CardDescription title='SKU' subtitle={product.ItemCode} />
                    <CardDescription title='Proveedor' subtitle={product.nombre_proveedor} />
                    <CardDescription title='Marca' subtitle={product.FirmName} />
                    <Tooltip title="NA">
                        <CardDescription title='Rotación' subtitle={product.categoria_rotacion} />
                    </Tooltip>
                </CardContent>

            </CardActionArea>
        </Card>
    )
}

export const ProductListGrid: FC<ProductListGridProps> = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
    } = props;

    return (
        <Stack
            sx={{
                width: '100%',
                position: 'relative'
            }}
        >
            <Grid
                container
                spacing={2}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                    width: '100%',
                    justifyContent: "space-evenly",
                }}
            >
                {
                    items.map((product) =>
                        <Grid item>
                            <ProductCard {...{ product }} />
                        </Grid>
                    )
                }
            </Grid>
            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Stack>
    );
};

ProductListGrid.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
