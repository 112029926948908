import Fab from '@mui/material/Fab';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Arrow = ({ direction , handleClick, sx } : Record<string, any>) => {

    const sx_icon = {
        width: '20px',
        color: 'white',
    }

    return (
        <Fab
            sx={{
                width: '40px',
                height: '40px',
                padding: "0",
                boxShadow: "unset",
                ...sx
            }}
            onClick={handleClick}
            color='primary'
            className="ArrowIcon"
        >
        {direction === "right" ? <ArrowForwardIosIcon sx={sx_icon} /> : <ArrowBackIosIcon sx={sx_icon} />}
        </Fab>
    );
};

export default Arrow;
