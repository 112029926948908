import type { FC } from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Logo } from '../../../components/logo';
import { Scrollbar } from '../../../components/scrollbar';
import { usePathname } from '../../../hooks/use-pathname';
import type { NavColor } from '../../../types/settings';
import type { Section } from '../config';
import { SideNavSection } from './side-nav-section';

import { Fab } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo(
    (): Record<string, string> => {
      switch (color) {
        case 'blend-in':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.background.default,
              '--nav-color': theme.palette.neutral[100],
              '--nav-border-color': theme.palette.neutral[700],
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[600],
              '--nav-item-icon-color': theme.palette.neutral[500],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[700],
              '--nav-item-chevron-color': theme.palette.neutral[700],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.background.default,
              '--nav-color': theme.palette.text.primary,
              '--nav-border-color': theme.palette.neutral[100],
              '--nav-logo-border': theme.palette.neutral[100],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.text.secondary,
              '--nav-item-hover-bg': theme.palette.action.hover,
              '--nav-item-active-bg': theme.palette.action.selected,
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[400],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[400],
              '--nav-item-chevron-color': theme.palette.neutral[400],
              '--nav-scrollbar-color': theme.palette.neutral[900]
            };
          }

        case 'discrete':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.neutral[900],
              '--nav-color': theme.palette.neutral[100],
              '--nav-border-color': theme.palette.neutral[700],
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[600],
              '--nav-item-icon-color': theme.palette.neutral[500],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[700],
              '--nav-item-chevron-color': theme.palette.neutral[700],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.neutral[50],
              '--nav-color': theme.palette.text.primary,
              '--nav-border-color': theme.palette.divider,
              '--nav-logo-border': theme.palette.neutral[200],
              '--nav-section-title-color': theme.palette.neutral[500],
              '--nav-item-color': theme.palette.neutral[500],
              '--nav-item-hover-bg': theme.palette.action.hover,
              '--nav-item-active-bg': theme.palette.action.selected,
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[400],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[400],
              '--nav-item-chevron-color': theme.palette.neutral[400],
              '--nav-scrollbar-color': theme.palette.neutral[900]
            };
          }

        case 'evident':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.neutral[800],
              '--nav-color': theme.palette.common.white,
              '--nav-border-color': 'transparent',
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.common.white,
              '--nav-item-disabled-color': theme.palette.neutral[500],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[500],
              '--nav-item-chevron-color': theme.palette.neutral[600],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.neutral[800],
              '--nav-color': theme.palette.common.white,
              '--nav-border-color': 'transparent',
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.common.white,
              '--nav-item-disabled-color': theme.palette.neutral[500],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[500],
              '--nav-item-chevron-color': theme.palette.neutral[600],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          }

        default:
          return {};
      }
    },
    [theme, color]
  );
};

interface SideNavProps {
  color?: NavColor;
  sections?: Section[];
  sideNavOpen: any;
}

export const SideNav: FC<SideNavProps> = (props) => {
  const { color = 'evident', sections = [], sideNavOpen } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'white',
          borderRightStyle: 'solid',
          borderRightWidth: '5px',
          color: 'var(--nav-color)',
          width: sideNavOpen.sideNavWidth,
          transition: 'width 300ms cubic-bezier(0.2,0,0,1) 0s',
          '&:hover': {
            '.MuiFab-root': {
              visibility: 'visible'
            }
          }
        }
      }}
      variant="permanent"
    >
      <Fab 
        color="primary"
        size='small'
        aria-label="sideNavButton"
        onClick={() => sideNavOpen.toggleOpen()}
          sx={{
            visibility: sideNavOpen.isSideNavOpen ? 'hidden' : 'visible',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(0, -50%)',
            width: '24px',
            height: '24px',
            minHeight: '24px',
        }}
      >
        {
          sideNavOpen.isSideNavOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />
        }
      </Fab>
      <Scrollbar
        sx={{
          visibility: sideNavOpen.isSideNavOpen ? 'visible' : 'hidden',
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 3 }}
          >
            <Box

              sx={{
                display: 'flex',
                p: 0,
                height: 'fit-content',
                width: '75%',
              }}
            >
              <Logo />
            </Box>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
  sections: PropTypes.array
};
