import React, { FC } from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface CircularLoaderProps {
    state: string
}

export const CircularLoader: FC<CircularLoaderProps> = ({state}) => {
    return (
        <Box
            sx={
                {
                    display: state === 'loading' ? 'flex' : 'none',
                    height: '100%',
                    justifyContent: 'center',
                    alignContent: 'center',
                }
            }>
            <CircularProgress
                size='4em'
                sx={{
                    color: 'red',
                    margin: 'auto',
                }}
            />
        </Box>
    )
}
