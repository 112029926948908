import React, { FC } from 'react';
import Box from '@mui/material/Box';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Typography from '@mui/material/Typography';

interface StateRequestScreenProps {
    readonly text : string
    readonly status : 'success' | 'error'
}
 
const StateRequestScreen: FC<StateRequestScreenProps> = ({text, status}) => {
    return ( 
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {
                status == 'success' ? <CheckCircleTwoToneIcon sx={{color: '#60d85a', width: '50%', height: '50%'}} />
                                    : <ErrorTwoToneIcon sx={{color: '#d8d25a', width: '50%', height: '50%'}} />

            }
                <Typography
                    color="text.primary"
                    variant="subtitle1"
                    align='center'
                    >
                    {text}
                </Typography>
        </Box>
    );
}
 
export default StateRequestScreen;

