import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { FC, useEffect, MouseEvent, ChangeEvent, useState, useCallback, useMemo } from 'react'
import { useStoreState, useStoreActions } from '../../store';
import { CircularLoader } from '../../components/circular-loader';
import { ProductListTable } from '../../components/list-table-products';
import StateRequestScreen from '../../components/state-request-screen';
import { useIsMount } from '../../hooks/use-is-mount';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ProductListGrid } from '../../components/grid-table-products';
import Chip from '@mui/material/Chip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import FiltersSideNav from '../../components/filers-side-nav';

interface ProductMasterProps {

}

export const ProductMaster: FC<ProductMasterProps> = () => {

  const requestAllPageData = useStoreActions((actions: any) => actions.productMaster.requestAllPageData);
  const requestProductsInPage = useStoreActions((actions: any) => actions.productMaster.requestProductsInPage);
  const setRowsPerPage = useStoreActions((actions: any) => actions.productMaster.setRowsPerPage);
  const setPage = useStoreActions((actions: any) => actions.productMaster.setPage);
  const setRequestSearchState = useStoreActions((actions: any) => actions.productMaster.setRequestSearchState);
  const setProductToSearch = useStoreActions((actions: any) => actions.productMaster.setProductToSearch);

  const filterOptions = useStoreState((state: any) => state.productMaster.filterOptions);
  const requestSearchState = useStoreState((state: any) => state.productMaster.requestSearchState);
  const productToSearch = useStoreState((state: any) => state.productMaster.productToSearch);
  const productsInPage = useStoreState((state: any) => state.productMaster.productsInPage);
  const page = useStoreState((state: any) => state.productMaster.page);
  const rowsPerPage = useStoreState((state: any) => state.productMaster.rowsPerPage);

  const emptyFilters = {
    getFirms: [],
    getSup: [],
    getCatRot: [],
    getItemName: [],
    productToSearch: [],
    order: {order: 'ASC', orderBy: 'ItemName'}
  }
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>(emptyFilters)

  const [productsView, setProductsView] = useState<'list' | 'grid'>('list');
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const isMount = useIsMount();

  const onChangeFilters = useCallback((data: Record<string, any>, checked: boolean) => {
    const { item, endpoint } = data
    setRequestSearchState('loading')
    setPage(1)

    if (endpoint === 'productToSearch') {
      if (checked) {
        setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [item] }))
      } else {
        setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [] }))
        setProductToSearch(null)
      }
    }
    else if (endpoint === 'order'){
      setActiveFilters((prevState) => ({ ...prevState, [endpoint]: {order: item.order, orderBy: item.orderBy} }))
    }
    else {
      if (checked) {
        setActiveFilters((prevState) => ({ ...prevState, [endpoint]: [...prevState[endpoint], item] }))
      } else {
        setActiveFilters((prevState) => ({ ...prevState, [endpoint]: prevState[endpoint].filter((current_item: Record<string, any>) => (current_item.id !== item.id)), }))
      }
    }
  }, [setRequestSearchState, setPage, setActiveFilters, setProductToSearch])

  const limpiarFiltros = () => {
    setActiveFilters(emptyFilters)
    setProductToSearch(null)
    setPage(1)
  }

  const getFiltersList = () => {
    let filters: Record<string, string> = {}

      Object.keys(activeFilters).forEach(function (key, index) {
        if(key !== 'order'){
          filters[key] = activeFilters[key].map((item: any) => item.id);
        } else{
          filters[key] = activeFilters[key];
        }
      });

    return filters
  }

  useEffect(() => {
    requestAllPageData()
  }, [requestAllPageData]);

  useEffect(() => {
    if (productToSearch) {
      onChangeFilters({ item: { value: productToSearch, id: productToSearch }, endpoint: 'productToSearch' }, true)
    }
  }, [productToSearch, onChangeFilters]);

  useEffect(() => {
    if (isMount) {
      const filters = getFiltersList()
      requestProductsInPage({ filters })
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (isMount) {
      const filters = getFiltersList()
      requestProductsInPage({ filters, description: productToSearch })
    }
  }, [activeFilters]);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setRequestSearchState('loading')
    setPage(newPage + 1)
  }

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRequestSearchState('loading')
    setRowsPerPage(event.target.value)
  }

  const handleProductsViewChange = (event: React.MouseEvent<HTMLElement>, nextView: 'list' | 'grid') => {
    nextView && setProductsView(nextView);
  };

  const submitHandler = useCallback((e: any) => {
    e.preventDefault()
    const value = e.target.item_name_search.value || ''
    onChangeFilters({ item: { value, id: value }, endpoint: 'getItemName' }, true)
  }, [onChangeFilters])

  const areActiveFilters = useMemo(
    () => Object.keys(activeFilters).some((key) => activeFilters[key].length > 0)
    , [activeFilters])

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <CircularLoader state={requestSearchState} />
      {
        requestSearchState !== 'error' ?
          <Stack
            gap={2}
            direction='row'
            justifyContent="space-around"
            sx={{
              width: '100%',
              padding: '20px 10px',
            }}
          >
            <FiltersSideNav
              isFiltersOpen={isFiltersOpen}
              filterOptions={filterOptions}
              activeFilters={activeFilters}
              submitHandler={submitHandler}
              onChangeFilters={onChangeFilters}
              sx={{
                minWidth: '20%'
              }}
            />
            <Box
              sx={{
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '10px'
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginBottom: '10px'
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="filtrar"
                    sx={{
                      borderRadius: '5px'
                    }}
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  >
                    <FilterAltIcon />
                    <Typography variant='body1' >Filtrar</Typography>
                  </IconButton>
                  <Box>
                    {
                      Object.keys(activeFilters).map((key: string) => (
                        (key !== 'order' && key !== 'orderBy') &&
                          activeFilters[key].map((item: Record<string, any>, idx: number) => (
                            <Chip
                              label={item.value}
                              color='primary'
                              variant="outlined"
                              key={item.id}
                              onDelete={() => onChangeFilters({ item, endpoint: key }, false)}
                            />
                          )
                          )
                        )
                      )
                    }
                    {areActiveFilters && <Button variant='text' onClick={() => limpiarFiltros()}> Limpiar Filtros </Button>}
                  </Box>
                </Stack>
                {
                  productsInPage?.Datos.length > 0 &&
                  <ToggleButtonGroup
                    value={productsView}
                    exclusive
                    onChange={handleProductsViewChange}
                    color="primary"
                    sx={{
                      width: 'fit-content',
                    }}
                  >
                    <ToggleButton value="list" aria-label="list">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid">
                      <ViewModuleIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
              </Stack>
              {
                (requestSearchState !== 'loading' && productsInPage) && (
                  productsView === 'list' ?
                    <ProductListTable
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onChangeFilters={onChangeFilters}
                      headers={[
                        { id: 'ItemName', label: 'Nombre', tooltip: "N/A" },
                        { id: 'FirmName', label: 'Marca', tooltip: "N/A" },
                        { id: 'SuppCatNum', label: 'Número de Parte (P/N)', tooltip: "N/A" },
                        { id: 'ItemCode', label: 'SKU', tooltip: "N/A" },
                        { id: 'categoria_rotacion', label: 'Rotación', tooltip: "Indica la frecuencia de venta del producto (en meses). Primera letra considera últimos 3 meses, segunda letra considera último año." },
                        { id: 'precio_adquisicion_sin_iva', label: 'Costo', tooltip: "Corresponde al precio de adquisición del articulo" },
                        { id: 'Available_clds', label: 'Stock', tooltip: "Stock físico en CLD para Serviall. Stock página web para Proveedores" }
                      ]}
                      activeFilters={activeFilters}
                      items={productsInPage['Datos']}
                      count={productsInPage['TotalRecords']}
                      page={page - 1}
                      rowsPerPage={rowsPerPage}
                    />
                    :
                    <ProductListGrid
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page - 1}
                      items={productsInPage['Datos']}
                      count={productsInPage['TotalRecords']}
                      rowsPerPage={rowsPerPage}
                    />
                )
              }
            </Box>
          </Stack>
          : <Box sx={{ width: '40%', display: 'flex', alignSelf: 'center', margin: 'auto', height: '100%' }}>
            <StateRequestScreen text='No es posible listar los productos en estos momentos' status='error' />
          </Box>
      }
    </Box>
  )
}
