import { OpportunityStatusEnum } from "../types/opportunity";

export type OpportunityStatusType = OpportunityStatusEnum | null;

const parseStatus = (status: OpportunityStatusType) => {
    switch (status) {
        case OpportunityStatusEnum.OPEN:
            return "Abierta";
        case OpportunityStatusEnum.IN_PROCESS:
            return "En Proceso";
        case OpportunityStatusEnum.OFFERTED:
            return "Ofertada";
        case OpportunityStatusEnum.EXPIRED:
            return "Expirada";
        case OpportunityStatusEnum.DECLINED:
            return "Declinada";
        case OpportunityStatusEnum.CLOSED:
            return "Cerrada";
        default:
            return ""
    }
}

export const getStatusID = (status: string) => {
    switch (status) {
        case "Abierta":
            return OpportunityStatusEnum.OPEN
        case "En Proceso":
            return OpportunityStatusEnum.IN_PROCESS
        case "Ofertada":
            return OpportunityStatusEnum.OFFERTED
        case "Expirada":
            return OpportunityStatusEnum.EXPIRED
        case "Declinada":
            return OpportunityStatusEnum.DECLINED
        case "Cerrada":
            return OpportunityStatusEnum.CLOSED
        default:
            return ""
    }
}

export default parseStatus;