import { FC, useState, useEffect, useMemo, MouseEvent, ChangeEvent } from 'react';
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from '../../store';
import { Box, Stack } from '@mui/system';
import { Card, CardContent, Typography, Button, Backdrop, CircularProgress, Snackbar, Alert, SnackbarCloseReason } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

import { format } from 'date-fns';
import { OpportunityItemsListTable } from '../../components/list-table-opportunity-items';
import ModalEmailOpportunity from '../../components/modal-email-opportunity';
import { CircularLoader } from '../../components/circular-loader';
import SaveIcon from '@mui/icons-material/Save';
import { SaveOpportunityPayloadType, OpportunityStatusEnum, OpportunityItemsType } from '../../types/opportunity';
import parseStatus from '../../utils/parseStatus';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from '../../components/date-picker';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
interface detailOpportunityHeaderListProps {
    readonly headerItems: Record<string, any>
    readonly setIsEmailModalOpen: any
    readonly idOpportunity: string,
    readonly nombreCliente: string,
    readonly opportunityDetail: Record<string, any>
}

const DetailOpportunityHeaderList: FC<detailOpportunityHeaderListProps> = ({ headerItems, setIsEmailModalOpen, idOpportunity, nombreCliente, opportunityDetail }) => {
    const [codeSnClient, setcodeSnClient] = useState( 
            headerItems.id_opportunities_table ? (
                headerItems['code_sn_client'] ? headerItems['code_sn_client'] : ''
            ) : 
            (
                headerItems['CardCode'] ? headerItems['CardCode'] : ''
            )
        )
    const [typeSell, settypeSell] = useState(headerItems['type_sell'] ? headerItems['type_sell'] : 'D1-10017')
    const [unitBusiness, setunitBusiness] = useState(headerItems['unit_business'] ? headerItems['unit_business'] : 'D2-10011')
    const [costCenter, setcostCenter] = useState(headerItems['cost_center'] ? headerItems['cost_center'] : 'D3-10049')
    const [dateDelivery, setdateDelivery] = useState(headerItems['date_delivery'] ? headerItems['date_delivery'] : null)
    const [placeDelivery, setplaceDelivery] = useState(headerItems['place_delivery'] ? headerItems['place_delivery'] : '')
    const [comments, setcomments] = useState(headerItems['comments'] ? headerItems['comments'] : '')
    const [status, setstatus] = useState('')
    const items = [

        { label: 'Nombre Cliente', value: headerItems['nombre_cliente'] },
        { label: 'Portal de Origen', value: headerItems['portal_de_origen'] },
        { label: 'Fecha de Publicación', value: headerItems['fecha_publicacion'] ? format(new Date(headerItems['fecha_publicacion']), 'dd/MM/yyyy HH:mm') : '' },
        { label: 'ID Oportunidad', value: headerItems['id_oportunidad'] },
        { label: 'Cantidad SKU', value: headerItems['Cantidad_sku'] },
        { label: 'Fecha de Cierre', value: headerItems['fecha_cierre'] ? format(new Date(headerItems['fecha_cierre']), 'dd/MM/yyyy HH:mm') : '' },

    ]

    const requestSaveOpportunityState = useStoreState((state: any) => state.opportunityDetail.requestSaveOpportunityState);
    const requestSaveOpportunity = useStoreActions((actions: any) => actions.opportunityDetail.requestSaveOpportunity);
    const setRequestSaveOpportunityState = useStoreActions((actions: any) => actions.opportunityDetail.setRequestSaveOpportunityState);

    const handleSaveOpportunityChanges = (status: SaveOpportunityPayloadType["status"]) => {

        const items_opportunity: Array<OpportunityItemsType> = opportunityDetail.map((item: any) => ({
            id: item.id,
            id_product: item.ItemCode_match_sugerido,
            index: item.index,
            offered: item.offered,
            cluster_match_requested: item.cluster_match_sugerido !== null ? item.cluster_match_sugerido.toString() : null,
            similarity_cosine_match_suggested: item.similitud_coseno_match_sugerido,
            score_prioritization_match_suggested: item.score_priorizacion_match_sugerido,
            name_provider_match: item.nombre_proveedor_match,
            amount_requested: item.cantidad_solicitada,
            itemname_requested: item.ItemName_solicitado,
            itemname_match_requested: item.ItemName_match_sugerido,
            itemcode_requested: item.ItemCode_solicitado,
            estimated_cost: item.estimated_cost_match_sugerido,

            stock: item.Available_clds && parseFloat(item.Available_clds),
            rotation_category: item.categoria_rotacion,
            // selling_price -> Is taken from state
        }))

        // const comments_add =  placeDelivery ? `${comments} - Lugar de entrega: ${placeDelivery}` : comments

        const data: SaveOpportunityPayloadType = {
            id: headerItems.id,
            id_opportunities_table: headerItems.id_opportunities_table,
            id_opportunity: idOpportunity,
            name_client: nombreCliente,
            number_opportunity_sap: headerItems.number_opportunity_sap,
            origin_portal: headerItems.portal_de_origen,
            code_sn_client: codeSnClient,
            type_sell: typeSell,
            unit_business: unitBusiness,
            cost_center: costCenter,
            date_delivery: dateDelivery ? dateDelivery : null,
            place_delivery: placeDelivery,
            comments: comments,
            status: status,
            items: items_opportunity
        }
        setRequestSaveOpportunityState('loading')
        requestSaveOpportunity(data)
    }

    const handleOnCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setRequestSaveOpportunityState(null);
    }

    return (
        <Stack
            direction='row'
            justifyContent="space-around"
            alignItems="center"
        >

            <>
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
                    open={requestSaveOpportunityState === 'loading'}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                {
                    (requestSaveOpportunityState === 'success' || requestSaveOpportunityState === 'error') &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={true}
                        autoHideDuration={3000}
                        onClose={handleOnCloseSnackbar}
                    >
                        <Alert
                            severity={requestSaveOpportunityState}
                            variant="filled"
                            sx={{ width: '100%', color: 'white' }}
                            onClose={handleOnCloseSnackbar}
                        >
                            {
                                requestSaveOpportunityState === 'success' ? "Oportunidad Guardada con Éxito" : "Ocurrio un error al guardar la oportunidad"
                            }
                        </Alert>
                    </Snackbar>
                }
            </>

            <List disablePadding>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Nombre Cliente
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['nombre_cliente']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Portal de Origen
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['portal_de_origen']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Fecha de Publicación
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['fecha_publicacion'] ? format(new Date(headerItems['fecha_publicacion']), 'dd/MM/yyyy HH:mm') : ''}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                ID Oportunidad
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['id_oportunidad']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Cantidad SKU
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['Cantidad_sku']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Direccion de entrega
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['lugar_entrega'] == '' ? headerItems['lugar_entrega'] : 'N/A'}
                                </Typography>
                            </Box>
                        )}
                    />

                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Fecha de Cierre
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['fecha_cierre'] ? format(new Date(headerItems['fecha_cierre']), 'dd/MM/yyyy HH:mm') : ''}
                                </Typography>
                            </Box>
                        )}
                    />

                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5, mt: 2 }}>
                    {/* <Button variant='contained' sx={{ margin: 'auto' }} startIcon={<EmailIcon />} onClick={() => setIsEmailModalOpen(true)}>Ver Correo</Button> */}
                    <ModalEmailOpportunity htmlEmail={headerItems['htmlBody']} />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5, ml: '18%' }}>
                    <Button variant='contained' sx={{ 'backgroundColor': '#343842', '&:hover': { 'backgroundColor': '#e1e1e1', 'color': 'black' } }} startIcon={<DeleteIcon />} onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.DECLINED)}>Declinar oferta</Button>
                </ListItem>
            </List>
            <List disablePadding>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Codigo SN Cliente
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={codeSnClient}
                                hiddenLabel
                                name="codeSnClient"
                                size="small"
                                onChange={(e) => setcodeSnClient(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Tipo de venta
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={typeSell}
                                hiddenLabel
                                name="typeSell"
                                size="small"
                                disabled
                                onChange={(e) => settypeSell(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Unidad de negocio
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={unitBusiness}
                                hiddenLabel
                                name="unitBusiness"
                                size="small"
                                disabled
                                onChange={(e) => setunitBusiness(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Centro de costos
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={costCenter}
                                hiddenLabel
                                name="costCenter"
                                size="small"
                                disabled
                                onChange={(e) => setcostCenter(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Plazo de entrega
                            </Typography>
                        )}
                        secondary={(
                            <DatePicker 
                                label="Fecha de Plazo Máximo"
                                date={dateDelivery}
                                setDate={setdateDelivery}
                                inputProps={{
                                    min: format(new Date(), 'yyyy-MM-dd')
                                }}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Lugar de entrega
                            </Typography>
                        )}
                        secondary={(

                            <TextField
                                defaultValue={placeDelivery}
                                hiddenLabel
                                name="placeDelivery"
                                size="small"
                                onChange={(e) => setplaceDelivery(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Comentarios adicionales
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={comments}
                                hiddenLabel
                                name="comments"
                                size="small"
                                onChange={(e) => setcomments(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <Button
                        variant='contained'
                        sx={{ 
                                margin: 'auto', 
                                '.MuiButton-startIcon>*:nth-of-type(1)': 
                                    {
                                        fontSize: '12px', 
                                        fontWeight: 'bolder'
                                    }
                            }}
                        startIcon={<Typography variant='caption'>SAP</Typography>} 
                        onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.OFFERTED)}
                        disabled={ !headerItems.id_opportunities_table }
                        >
                        Enviar/Actualizar oferta a SAP
                    </Button>

                    <Button variant='contained' sx={{ marginRight: 'auto', 'backgroundColor': '#737373', '&:hover': {'backgroundColor': '#e1e1e1', 'color': 'black'} }} startIcon={<DownloadIcon />} onClick={() => setIsEmailModalOpen(true)}>Descargar oferta</Button>
                </ListItem>
                <ListItem sx={{ px: 20 }}>
                    <Button variant='contained' sx={{ marginRight: 0, 'backgroundColor': '#343842', '&:hover': { 'backgroundColor': '#e1e1e1', 'color': 'black' } }} startIcon={<SaveIcon />} onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.IN_PROCESS)}>Guardar cambios</Button>
                </ListItem>

            </List>

        </Stack>
    )
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}


const OpportunityDetailPage: FC = ({ }) => {
    const navigate = useNavigate();
    let query = useQuery();
    const idOpportunity = decodeURIComponent(query.get('id_oportunidad') || '')
    const nombreCliente = decodeURIComponent(query.get('nombre_cliente') || '')

    const opportunityDetail = useStoreState((state: any) => state.opportunityDetail.opportunityDetail);
    const opportunityHeader = useStoreState((state: any) => state.opportunityDetail.opportunityHeader);
    const requestOpportunityDetail = useStoreActions((actions: any) => actions.opportunityDetail.requestOpportunityDetail);
    const requestFilterOptions = useStoreActions((actions: any) => actions.opportunityDetail.requestFilterOptions);
    const setOpportunityDetail = useStoreActions((actions: any) => actions.opportunityDetail.setOpportunityDetail);
    const requestSearchState = useStoreState((state: any) => state.opportunityDetail.requestSearchState);

    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false)

    useEffect(() => {
        requestOpportunityDetail({ idOpportunity, nombreCliente })

        return () => {
            setOpportunityDetail(null)
        }
    }, [idOpportunity, nombreCliente, requestOpportunityDetail])

    useEffect(() => {
        requestFilterOptions()
    }, [])

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage + 1)
    }

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value))
    }

    if (!opportunityDetail) return (
        <Box
            height='100%'
            width='100%'
        >
            <CircularLoader state={requestSearchState} />
        </Box>
    )

    return (
        <>
            <Stack>
                <Card
                    sx={{
                        width: '95%',
                        margin: '20px auto',
                        borderTop: '10px solid #e84145',
                    }}
                >
                    <CardContent>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Typography
                                variant='h3'
                                marginBottom='15px'
                                sx={{
                                    borderBottom: '2px solid red',
                                    display: 'inline-block',
                                    paddingBottom: '4px',
                                }}
                            >
                                <Button variant='contained' sx={{ 'backgroundColor': '#343842', '&:hover': { 'backgroundColor': '#e1e1e1', 'color': 'black' } }} onClick={() => navigate(-1)}><ArrowBackIcon /></Button>
                                Detalle de Oportunidad
                                {
                                    opportunityHeader.id_opportunities_table &&
                                    <Typography variant='subtitle1' display='inline' component='span' color='#737373'>
                                        (#{opportunityHeader.id_opportunities_table})
                                    </Typography>
                                }
                            </Typography>
                            <Box>
                                <Typography
                                    variant='body1'
                                    bgcolor='#e5e940'
                                    padding='2px 5px'
                                    borderRadius='5px'
                                >
                                    Estado Oportunidad: <Typography variant='body1' fontWeight='bold' display='inline' component="span">{parseStatus(opportunityHeader.status)}</Typography>
                                </Typography>

                                <Typography variant='body1'>N° Cotización SAP: {opportunityHeader.number_opportunity_sap ? opportunityHeader.number_opportunity_sap : '-' }</Typography>
                            </Box>
                        </Stack>
                        <DetailOpportunityHeaderList
                            headerItems={opportunityHeader}
                            setIsEmailModalOpen={setIsEmailModalOpen}
                            idOpportunity={idOpportunity}
                            nombreCliente={nombreCliente}
                            opportunityDetail={opportunityDetail}
                        />
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        width: '95%',
                        margin: '5px auto'
                    }}
                >
                    <CardContent
                        sx={{
                            overflow: 'auto'
                        }}
                    >
                        <OpportunityItemsListTable
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            headers={[
                                { id: 'ofertar', label: 'Ofertar' },
                                { id: 'editar', label: 'Editar' },
                                { id: 'ItemCode_solicitado', label: 'Sku Cliente' },
                                { id: 'ItemName_solicitado', label: 'Descripción' },
                                { id: 'ItemName_match_sugerido', label: 'Match Sugerido' },
                                { id: 'ItemCode_match_sugerido', label: 'SKU Match Sugerido' },
                                { id: 'nombre_proveedor_match', label: 'Proveedor Match' },
                                { id: 'rotation_category', label: 'Rotación' },
                                { id: 'cantidad_solicitada', label: 'Cantidad' },
                                { id: 'Available_clds', label: 'Stock disponible' },
                                { id: 'cluster_match_sugerido', label: 'Calidad Match' },
                                { id: 'similitud_coseno_match_sugerido', label: '% Confiabilidad Match' },
                                { id: 'estimated_cost', label: 'Costo Estimado' },
                                { id: 'selling_price', label: 'Precio de Venta ' },
                            ]}
                            items={opportunityDetail.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)}
                            count={opportunityDetail.length}
                            page={page - 1}
                            rowsPerPage={rowsPerPage}
                        />
                    </CardContent>
                </Card>
            </Stack>
        </>
    );
};

export default OpportunityDetailPage;
