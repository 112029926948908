import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface DemandModelProps {
    requestSearchState: null | 'loading' | 'error' | 'success';
    loadingTableState: null | 'loading' | 'error' | 'success';
    demandList: Record<string, any> | null;
    page: number;
    rowsPerPage: number;
    filterOptions: Record<any, any> | null;
    activeFilters: {
        portal_de_origen: Array<string>,
        nombre_cliente: Array<string>,
        status: Array<string>,
        id_oportunidad: string | null,
        create_date: {'start': string | null, 'end': string | null},
        expiration_date: {'start': string | null, 'end': string | null},
        totalSKU: {'min': number | null, 'max': number | null},
        order: {'order': string | null, 'orderBy': string | null},
    };

    requestDemandList: Thunk<DemandModelProps>;
    requestFilterOptions: Thunk<DemandModelProps>;

    setDemandList: Action<DemandModelProps, Record<string, any> | null>;
    setRequestSearchState: Action<DemandModelProps, null | 'loading' | 'error' | 'success'>;
    setLoadingTableState: Action<DemandModelProps, null | 'loading' | 'error' | 'success'>;
    setPage: Action<DemandModelProps, number>;
    setRowsPerPage: Action<DemandModelProps, number>;
    setFilterOptions: Action<DemandModelProps, Record<string, any> | null>;
    setActiveFilters: Action<DemandModelProps, Record<string, any> | null>;
}

const empty_activeFilters = {
    portal_de_origen: [],
    nombre_cliente: [],
    status: [],
    id_oportunidad: null,
    create_date: {start: null, end: null},
    expiration_date: {start: null, end: null},
    totalSKU: {min: null, max: null},
    order: {order: 'desc', orderBy: 'fecha_publicacion'},
}

const demandModel: DemandModelProps = {

    demandList: null,
    requestSearchState: null,
    loadingTableState: null,
    page: 1,
    rowsPerPage: 10,
    filterOptions: null,
    activeFilters: empty_activeFilters,

    setDemandList: action((state, payload) => {
        state.demandList = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setLoadingTableState: action((state, payload) => {
        state.loadingTableState = payload;
    }),

    setPage: action((state, payload) => {
        state.page = payload;
    }),

    setRowsPerPage: action((state, payload) => {
        state.rowsPerPage = payload;
    }),

    setFilterOptions: action((state, payload) => {
        state.filterOptions = payload;
    }),

    setActiveFilters: action((state, payload) => {
        if(payload && 'clear' in payload){
            state.activeFilters = empty_activeFilters
        } else{
            state.activeFilters = {...state.activeFilters, ...payload};
        }
    }),

    requestDemandList: thunk(async (actions, payload, { getState }) => {

        const { page, rowsPerPage, activeFilters } = getState()

        try {
            const response = await axios.post(
                '/demand/list',
                {
                    page,
                    rowsPerPage,
                    portal_de_origen: activeFilters.portal_de_origen,
                    nombre_cliente: activeFilters.nombre_cliente,
                    status: activeFilters.status,
                    id_oportunidad: activeFilters.id_oportunidad,
                    create_date: (activeFilters.create_date.start && activeFilters.create_date.end) && activeFilters.create_date,
                    expiration_date: (activeFilters.expiration_date.start && activeFilters.expiration_date.end) && activeFilters.expiration_date,
                    cantidad_sku: (activeFilters.totalSKU.min) && activeFilters.totalSKU,
                    order: activeFilters.order,
                }
                
            );
            actions.setDemandList(response.data['data'])
            actions.setRequestSearchState(response.data === 200 && !response.data['error'] ? 'success' : 'error')
            actions.setLoadingTableState(response.data === 200 && !response.data['error'] ? 'success' : 'error')
            
            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);

            return false;
        }
    }),

    requestFilterOptions: thunk(async (actions) => {
        try {
            actions.setFilterOptions(null)

            const response = await axios.get(`/filters/demand_options`);

            if(response.status === 200 && !response.data['error'] ){
                actions.setFilterOptions(response.data['data'])
                console.log(response.data['data'])
            } else {
                actions.setFilterOptions(null)
            }

            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }
    }),
};

export default demandModel;