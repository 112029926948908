import CarouselSlide from "./CarouselSlide";
import Arrow from "./Arrow";
import React, { FC, useState } from "react";
import { Box, Slide } from "@mui/material";
interface ImageSliderProps {
  readonly images : Record<string, any>
}

// https://codesandbox.io/p/sandbox/slider-image-material-ui-y2vo1?file=%2Fsrc%2Fstyles.css%3A1%2C7-8%2C1
const ImageSlider : FC<ImageSliderProps> = ({images}) => {
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right' | 'up' | 'down'>("down");

  const [index, setIndex] = useState(0);

  const onArrowClick = (direction: 'left' | 'right' | 'up' | 'down') => {

    const increment = direction === "left" ? -1 : 1;
    const oppDirection = direction === "left" ? "right" : "left";
    setSlideDirection(direction);
    setSlideIn(false);
    
    setTimeout(() => {
      const newIndex = (index + increment + images['images']?.length) % images['images']?.length;
      setIndex(newIndex);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 400);
  };

  const containerRef = React.useRef<HTMLElement>(null);

  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100%',
      }}
    >
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: 'relative',
        width: "100%",
        height: "100%",
        "&:hover": {
          '.ArrowIcon': {
            visibility: images['images']?.length > 1 ? 'visible' : 'hidden',
            opacity: 1,
            transition: 'visibility 0s, opacity 0.2s linear'
          }
        }
      }}>
        <Arrow 
          direction="left" 
          handleClick={() => onArrowClick("left")}
          sx={{
            position: 'absolute',
            left: 0,
            visibility: 'hidden',
            opacity: 0,
            transition: '0.8s ease'
          }}
          />
        <Slide in={slideIn} direction={slideDirection} container={containerRef.current}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <CarouselSlide 
              content={ images['type'] === 'base64' ? images['images'][index]['Archivo'] : images['images'][0] }
              type={images['type']}
            />
          </Box>
        </Slide>
        <Arrow 
          direction="right" 
          handleClick={() => onArrowClick("right")}
          sx={{
            position: 'absolute',
            right: 0,
            visibility: 'hidden',
            opacity: 0,
            transition: '0.3s ease'
          }}
          />
      </Box>
    </Box>
  );
}

export default ImageSlider;