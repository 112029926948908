import { FC, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import StateRequestScreen from './state-request-screen';
import { Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

interface ModalEmailOpportunityProps {
    readonly htmlEmail: string;
}

const ModalEmailOpportunity: FC<ModalEmailOpportunityProps> = ({ htmlEmail }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Button variant='contained' sx={{ margin: 'auto' }} startIcon={<EmailIcon />} onClick={() => setIsOpen(true)}>Ver Correo</Button>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        width: '1000px',
                        height: '75%',
                        backgroundColor: 'white',
                        margin: 'auto',
                        overflow: 'scroll',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {
                        htmlEmail ?
                            <div dangerouslySetInnerHTML={{ __html: htmlEmail }} />
                            :
                            <Box width='50%' margin='auto' height='75%' display='flex'>
                                <StateRequestScreen status='error' text='No se puede visualizar el correo' />
                            </Box>

                    }
                </Box>
            </Modal>
        </>
    )
}

export default ModalEmailOpportunity;