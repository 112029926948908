import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface BinnacleModelProps {
    filterOptions: Record<any, any> | null;
    requestSearchState: null | 'loading' | 'error' | 'success';
    page: number;
    rowsPerPage: number;
    binnaclesInPage: Record<any, any> | null;
    binnacleToSearch: null | string;

    requestFilterOptions: Thunk<BinnacleModelProps, string>;
    requestBinnacleInPage: Thunk<BinnacleModelProps, Record<string, any> | null>;
    requestAllPageData: Thunk<BinnacleModelProps>;

    setProductToSearch: Action<BinnacleModelProps, null | string>;
    setRequestSearchState: Action<BinnacleModelProps, null | 'loading' | 'error' | 'success'>;
    setFilterOptions: Action<BinnacleModelProps, Record<string, any> | null>;
    setBinnaclesInPage: Action<BinnacleModelProps, Record<string, any> | null>;
    setPage: Action<BinnacleModelProps, number>;
    setRowsPerPage: Action<BinnacleModelProps, number>;
}

const BinnacleModel: BinnacleModelProps = {
    filterOptions: null,
    requestSearchState: null,
    binnaclesInPage: null,
    binnacleToSearch: null,

    page: 1,
    rowsPerPage: 10,

    setFilterOptions: action((state, payload) => {
        state.filterOptions = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setBinnaclesInPage: action((state, payload) => {
        state.binnaclesInPage = payload;
    }),

    setPage: action((state, payload) => {
        state.page = payload;
    }),

    setRowsPerPage: action((state, payload) => {
        state.rowsPerPage = payload;
    }),

    setProductToSearch: action((state, payload) => {
        state.binnacleToSearch = payload;
    }),

    requestBinnacleInPage: thunk(async (actions, payload: Record<string, any> | null, { getState }) => {
        try {
            actions.setRequestSearchState('loading')

            const { page, rowsPerPage, binnaclesInPage, binnacleToSearch } = getState()

            actions.setBinnaclesInPage(null)

            const getFirms = payload?.filters?.getFirms || null
            const getSup = payload?.filters?.getSup || null
            const getCatRot = payload?.filters?.getCatRot || null
            const getItemName = payload?.filters?.getItemName || null

            const description = payload?.description || binnacleToSearch || null

            let response = null

            if (binnacleToSearch) {

                if (payload?.description) {
                    response = await axios.get('/binnacle/list',
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

                    actions.setPage(1)

                    response = {
                        ...response,
                        data: {
                            ...response.data,
                            data: {
                                allData: response.data['data'],
                                TotalPages: Math.ceil(response.data['data'].length / rowsPerPage),
                                TotalRecords: response.data['data'].length,
                                Datos: response.data['data'].slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
                                Page: 1,
                                isIASearch: true,
                            },
                        }
                    }
                } else {
                    response = {
                        status: 200,
                        data: {
                            error: false,
                            data: {
                                ...binnaclesInPage,
                                Datos: binnaclesInPage!.allData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
                                Page: page,
                                isIASearch: true,
                            }
                        }
                    }
                }


            } else {
                response = await axios.post(`/binnacle-master/articulos`,
                    {
                        firms: getFirms?.length > 0 ? getFirms : null,
                        lics: getSup?.length > 0 ? getSup : null,
                        cats: getCatRot?.length > 0 ? getCatRot : null,
                        itemName: getItemName?.length > 0 ? getItemName[0] : null,
                        page,
                        rowsPerPage,
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
            }

            actions.setBinnaclesInPage(response.data['data']);
            actions.setRequestSearchState((response.status === 200 && response.data.error === false) ? 'success' : 'error')

            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching items in page:', error);
            return false;
        }
    }),

    requestAllPageData: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setRequestSearchState('loading')
            actions.setBinnaclesInPage(null)
            actions.setFilterOptions(null)

            /* ------------------------ REQUEST PRODUCTS IN PAGE ------------------------ */
            const { page, rowsPerPage } = getState()
            const response_articulos = await axios.get(
                `/binnacle/list`, 
                {
                    params: {
                        page,
                        size: rowsPerPage
                    }
                }
            );

            /* ------------------------- REQUEST FILTER OPTIONS ------------------------- */
            //const response_filter_options = await axios.get(`/binnacle-master/filter-options`, { params: { 'page': 1, 'rowsPerPage': 500 } });

            //actions.setFilterOptions(response_filter_options.data['data'])
            
            actions.setRequestSearchState(response_articulos.status === 200 /*&& response_filter_options.status === 200*/ ? 'success' : 'error')
            actions.setBinnaclesInPage(response_articulos.data);
            
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching items in page:', error);
            return false;
        }
    }),

    requestFilterOptions: thunk(async (actions) => {
        try {
            actions.setRequestSearchState('loading')
            actions.setFilterOptions(null)

            const response = await axios.get(`/binnacle-master/filter-options`, { params: { 'page': 1, 'rowsPerPage': 500 } });

            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            actions.setFilterOptions(response.data['data']);

            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);
            return false;
        }
    }),

};

export default BinnacleModel;