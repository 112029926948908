import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface SearchModel {
    products: [];
    setproducts: Action<SearchModel, []>;
    setProductToSearch: Action<SearchModel, null | string>;
    setRequestSearchState: Action<SearchModel, null | 'loading' | 'error' | 'success'>;
    match_item: Thunk<SearchModel>;
    search: Thunk<SearchModel>;
    requestSearchState: null | 'loading' | 'error' | 'success';
    productToSearch: null | string;
}

const searchModel: SearchModel = {
    products: [],
    requestSearchState: null,
    productToSearch: null,

    setproducts: action((state, payload) => {
        state.products = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setProductToSearch: action((state, payload) => {
        state.productToSearch = payload;
    }),

    match_item: thunk(async (actions, values: any) => {
        try {
            console.log(values)
            const response = await axios.post('/search/match_item', { description: values.description, quantity: values.quantity }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            actions.setproducts(response.data['data']);
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }

    }),
    search: thunk(async (actions, values: any) => {
        try {
            console.log(values)
            actions.setRequestSearchState('loading')
            actions.setproducts([])
            actions.setProductToSearch(null)
            const response = await axios.post('/search/search', { description: values.description }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            actions.setproducts(response.data['data']);
            actions.setProductToSearch(response.status === 200 && values.description )
            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);
            return false;
        }

    }),
};

export default searchModel;
