import type { FC } from 'react';
import logo from '../assets/allconect-full.png';
import Link from '@mui/material/Link';

export const Logo: FC = () => {
  return (
    <Link
      href='/maestra-productos'
      sx={
        {
          width: '100%',
          height: '100%',
        }
      }
    >
      <img src={logo} alt="Logo" style={{width: '100%'}} />
    </Link>
  );
};
