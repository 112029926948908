export enum OpportunityStatusEnum {
    OPEN = 'open',
    CLOSED = 'closed',
    IN_PROCESS = 'in_process',
    OFFERTED = 'offerted',
    EXPIRED = 'expired',
    DECLINED = 'declined',
}

export interface OpportunityStatusType {
    readonly status: null | OpportunityStatusEnum.OPEN | OpportunityStatusEnum.IN_PROCESS | OpportunityStatusEnum.OFFERTED | OpportunityStatusEnum.EXPIRED | OpportunityStatusEnum.DECLINED
}

export interface OpportunityItemsType {
    readonly id: number | null;
    readonly id_product: string;
    readonly index: number;
    readonly offered: boolean;

    readonly cluster_match_requested: 'manual' | '0' | '1' | '2' | '3' | '4'
    readonly similarity_cosine_match_suggested: number
    readonly score_prioritization_match_suggested: number
    readonly name_provider_match: string
    readonly amount_requested: number
    readonly itemname_requested: string
    readonly itemname_match_requested: string
    readonly itemcode_requested: string
    readonly estimated_cost: number
    readonly stock: number
    readonly rotation_category: string
}

export interface SaveOpportunityPayloadType extends OpportunityStatusType {
    readonly id: number | null;
    readonly id_opportunities_table: number | null;
    readonly id_opportunity: string;
    readonly name_client: string;
    readonly origin_portal: string | null;

    items: Array<OpportunityItemsType>

    readonly number_opportunity_sap: string | null;
    readonly code_sn_client: string | null;
    readonly type_sell: string | null;
    readonly unit_business: string | null;
    readonly cost_center: string | null;
    readonly date_delivery: string | null;
    readonly place_delivery: string | null;
    readonly comments: string | null;
}