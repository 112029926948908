import { useState, FC, MouseEvent, ChangeEvent, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { Autocomplete, Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PropertyList } from './property-list';
import { PropertyListItem } from './property-list-item';
import { SelectMatchListTable } from './list-table-select-matches';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useStoreState, useStoreActions } from '../store';
import { ProductListTableItems } from './list-table-products-items';
import { CircularLoader } from './circular-loader';

interface detailItemListProps {
    readonly itemDetail: Record<string, any>
}

const DetailItemList: FC<detailItemListProps> = ({ itemDetail }) => {

    const items = [
        { label: 'Descripción Cliente', value: itemDetail['ItemName_solicitado'] },
        { label: 'Cantidad Solicitada', value: itemDetail['cantidad_solicitada'] },
    ]

    const items_match = [
        { label: 'Match Seleccionado', value: itemDetail['ItemName_match_sugerido'] },
        { label: 'SKU Match Seleccionado', value: itemDetail['ItemCode_match_sugerido'] },
    ]

    return (
        <Stack
            direction='row'
            justifyContent="space-around"
            alignItems="center"
            padding='20px'
        >
            <PropertyList>
                {
                    items.map(({ label, value }, index) =>
                        <PropertyListItem
                            key={index}
                            align='horizontal'
                            divider
                            label={label}
                            value={value ? value.toString() : 'NA'}
                            sx={{
                                '.MuiTypography-root': {
                                    fontSize: '1rem'
                                }
                            }}
                        />
                    )
                }
            </PropertyList>
            <PropertyList>
                {
                    items_match.map(({ label, value }, index) =>
                        <PropertyListItem
                            key={index}
                            align='horizontal'
                            divider
                            label={label}
                            value={value ? value.toString() : 'NA'}
                            sx={{
                                '.MuiTypography-root': {
                                    fontSize: '1rem'
                                }
                            }}
                        />
                    )
                }
            </PropertyList>
        </Stack>
    )
}



interface ModalMatchItemOpportunityProps {
    readonly isOpen: boolean;
    readonly handleClose: () => void;
    readonly itemDetailIndex: number | null
}

const ModalMatchItemOpportunity: FC<ModalMatchItemOpportunityProps> = ({ isOpen, handleClose, itemDetailIndex }) => {

    const suppliersList = useStoreState((state: any) => state.opportunityDetail.suppliersList);
    const firmsList = useStoreState((state: any) => state.opportunityDetail.firmsList);
    const filteredItems = useStoreState((state: any) => state.opportunityDetail.filteredItems);
    const requestItemsSearchState = useStoreState((state: any) => state.opportunityDetail.requestItemsSearchState);
    const opportunityDetail = useStoreState((state: any) => state.opportunityDetail.opportunityDetail);

    const requestFilteredItems = useStoreActions((actions: any) => actions.opportunityDetail.requestFilteredItems);
    const setFilteredItems = useStoreActions((actions: any) => actions.opportunityDetail.setFilteredItems);
    const setRequestItemsSearchState = useStoreActions((actions: any) => actions.opportunityDetail.setRequestItemsSearchState);


    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)
    const [value, setValue] = useState('1');

    const refForm = useRef<any>(null)

    useEffect(() => {
        if(refForm?.current){
            const data = {
                page,
                rowsPerPage,
                item_code: refForm?.current.sku_search.value || null,
                modelo: encodeURIComponent(refForm?.current.model_search.value) || null,
                item_name: encodeURIComponent(refForm?.current.item_name_search.value) || null,
                nombre_proveedor: encodeURIComponent(refForm?.current.combo_box_supplier.value) || null,
                firm_name: encodeURIComponent(refForm?.current.combo_box_firm.value) || null
            }
            
            setRequestItemsSearchState('loading')
            requestFilteredItems({...data})
            setPage(1)
        }
    }, [page, rowsPerPage])

    useEffect( () => {
        setPage(1)
        setRowsPerPage(5)
    }, [value])
    

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage + 1)
    }

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value))
    }

    const onClose = () => {
        handleClose();
        setFilteredItems(null);
        setValue('1')
    }

    const submitHandler = (e: any) => {
        e.preventDefault()

        const data = {
            page,
            rowsPerPage,
            item_code: e.target.sku_search.value || null,
            modelo: encodeURIComponent(e.target.model_search.value) || null,
            item_name: encodeURIComponent(e.target.item_name_search.value) || null,
            nombre_proveedor: encodeURIComponent(e.target.combo_box_supplier.value) || null,
            firm_name: encodeURIComponent(e.target.combo_box_firm.value) || null
        }
        
        setRequestItemsSearchState('loading')
        requestFilteredItems({...data})
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-FormLabelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                itemDetailIndex !== null ? <Card
                    sx={{
                        width: '80%',
                        height: '80%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'auto'
                    }}
                >
                    <CardContent
                        sx={{
                            overflow: 'auto'
                        }}
                    >
                        <Stack>
                            <Typography
                                variant="h3"
                                sx={{
                                    borderBottom: '2px solid red',
                                    display: 'inline-block',
                                    paddingBottom: '4px',
                                    width: 'fit-content'
                                }}
                            >
                                Detalle Linea
                            </Typography>

                            <DetailItemList itemDetail={opportunityDetail[itemDetailIndex]} />

                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Matches Sugeridos" value="1" />
                                            <Tab label="Asignar Match" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <SelectMatchListTable
                                            onPageChange={handlePageChange}
                                            onRowsPerPageChange={handleRowsPerPageChange}
                                            headers={[
                                                { id: 'ItemCode', label: 'SKU' },
                                                { id: 'ItemName', label: 'Descripción' },
                                                { id: 'nombre_proveedor', label: 'Proveedor' },
                                                { id: 'categoria_rotacion', label: 'Rotación' },
                                                { id: 'Available_clds', label: 'Stock Disponible' },
                                                { id: 'estimated_cost', label: 'Costo Estimado' },
                                                { id: 'selling_price', label: 'Precio De Venta' },
                                                { id: 'cluster', label: 'Calidad Match' },
                                                { id: 'similitud_coseno', label: 'Confiabilidad Match' },
                                                { id: 'score_priorizacion', label: 'Prioridad' },
                                            ]}
                                            items={opportunityDetail[itemDetailIndex]['lista_matches'].slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)}
                                            count={opportunityDetail[itemDetailIndex]['lista_matches'].length}
                                            page={page - 1}
                                            rowsPerPage={rowsPerPage}
                                            itemDetail={opportunityDetail[itemDetailIndex]}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <Box component="form" onSubmit={submitHandler} ref={refForm} >
                                                <Grid container>
                                                    <Grid xs={6}>
                                                        <Stack width='75%'>
                                                            <TextField
                                                                name="sku_search"
                                                                label="SKU"
                                                                margin='normal'
                                                                size='small'
                                                            />
                                                            <TextField
                                                                name="item_name_search"
                                                                label="Nombre"
                                                                margin='normal'
                                                                size='small'
                                                            />
                                                            <TextField
                                                                name="model_search"
                                                                label="Modelo"
                                                                margin='normal'
                                                                size='small'
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Stack width='75%' height='100%' >
                                                            <Autocomplete
                                                                disablePortal
                                                                id="combo_box_supplier"
                                                                options={suppliersList}
                                                                sx={{ margin: '16px 0 8px 0' }}
                                                                noOptionsText='No se encontraron resultados'
                                                                renderInput={(params) => <TextField {...params} label="Proveedor" />}
                                                            />
                                                            <Autocomplete
                                                                disablePortal
                                                                id="combo_box_firm"
                                                                options={firmsList}
                                                                sx={{ margin: '16px 0 8px 0' }}
                                                                noOptionsText='No se encontraron resultados'
                                                                renderInput={(params) => <TextField {...params} label="Marca" />}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>

                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        margin: '10px auto',
                                                        width: '25%',
                                                        display: 'flex'
                                                    }}
                                                    type='submit'
                                                >
                                                    Buscar
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Box margin='20px'>
                                                    <CircularLoader state={requestItemsSearchState} />
                                                </Box>
                                                {
                                                    filteredItems && 
                                                        <ProductListTableItems
                                                            onPageChange={handlePageChange}
                                                            onRowsPerPageChange={handleRowsPerPageChange}
                                                            headers={[
                                                                { id: 'checkbox', label: '', tooltip: '' },
                                                                { id: 'ItemCode', label: 'SKU', tooltip: '' },
                                                                { id: 'ItemName', label: 'Descripción', tooltip: '' },
                                                                { id: 'nombre_proveedor', label: 'Nombre Proveedor', tooltip: '' },
                                                                { id: 'categoria_rotacion', label: 'Rotación', tooltip: '' },
                                                                { id: 'Available_clds', label: 'Stock Disponible', tooltip: '' },
                                                                { id: 'precio_adquisicion_sin_iva', label: 'Costo Estimado', tooltip: '' },
                                                                { id: 'precio_normal_sin_iva', label: 'Precio Venta', tooltip: '' },
                                                                { id: 'FirmName', label: 'Marca', tooltip: '' },
                                                            ]}
                                                            items={filteredItems.data}
                                                            count={filteredItems.TotalRecords}
                                                            page={page - 1}
                                                            rowsPerPage={rowsPerPage}
                                                            itemDetail={opportunityDetail[itemDetailIndex]}
                                                        />
                                                }
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                </TabContext>
                            </Box>

                        </Stack>

                    </CardContent>
                </Card> : <h1>Loading...</h1>
            }
        </Modal>
    )
}

export default ModalMatchItemOpportunity;