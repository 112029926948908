import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';


interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string, tooltip: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <Tooltip title={headCell.tooltip}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface BinnacleListTableProps {
    count?: number;
    items?: Product[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string, tooltip: string }>;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: Product[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const BinnacleListTable: FC<BinnacleListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 10,
        headers = []
    } = props;

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<any>('created_at');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        console.log(property)
    };

    const visibleRows = useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)),
        [order, orderBy, items],
    );

    return (
        <Box>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {visibleRows.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                            >
                                {
                                    headers.map((item_header, idx) => {
                                        if(item_header.id === 'created_at'){
                                            return <TableCell align="left" key={idx}>
                                                {row[item_header.id] ? format(new Date(row[item_header.id].toString()), 'dd/MM/yyyy HH:mm') : ''}
                                                </TableCell>
                                        } else{
                                            return <TableCell align="left" key={idx}>{row[item_header.id]}</TableCell>
                                        }
                                    }
                                    )
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

BinnacleListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
