import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import { Link } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import ProductImage from './product-image';
import parseCurrency from '../utils/parseCurrency';


interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string, tooltip: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <Tooltip title={headCell.tooltip}>
                            <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                          </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface ProductListTableProps {
    count?: number;
    items?: Product[];
    activeFilters?: any;
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeFilters: (data: any, checked: boolean) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string, tooltip: string }>;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: Product[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const ProductListTable: FC<ProductListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        activeFilters,
        onPageChange = () => { },
        onRowsPerPageChange,
        onChangeFilters,
        page = 0,
        rowsPerPage = 10,
        headers = []
    } = props;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = activeFilters.order.orderBy === property && activeFilters.order.order === 'asc';
        onChangeFilters({endpoint: 'order', item: {order: isAsc ? 'desc' : 'asc', orderBy: property} }, true)
    };

    return (
        <Box>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={activeFilters.order.order}
                    orderBy={activeFilters.order.orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {items.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                component={Link} to={`/detalle-producto?ItemCode=${row.ItemCode}`}
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none'
                                }}
                            >
                                <TableCell>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}
                                    >
                                        <ProductImage ItemCode={row.ItemCode} width={'100px'} height={'100px'} link_imagen={row.link_imagen} />
                                    </Box>
                                </TableCell>
                                <TableCell align="left">{row.ItemName}</TableCell>
                                <TableCell align="left">{row.FirmName}</TableCell>
                                <TableCell align="left">{row.SuppCatNum}</TableCell>
                                <TableCell align="left">{row.ItemCode}</TableCell>
                                <TableCell align="left">{row.categoria_rotacion}</TableCell>
                                <TableCell align="left">{ parseCurrency(row.precio_adquisicion_sin_iva || 0) }</TableCell>
                                <TableCell align="left">{row.Available_clds}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

ProductListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
