import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface ProductMasterModelProps {
    filterOptions: Record<any, any> | null;
    requestSearchState: null | 'loading' | 'error' | 'success';
    page: number;
    rowsPerPage: number;
    productsInPage: Record<any, any> | null;
    productToSearch: null | string;

    requestFilterOptions: Thunk<ProductMasterModelProps, string>;
    requestProductsInPage: Thunk<ProductMasterModelProps, Record<string, any> | null>;
    requestAllPageData: Thunk<ProductMasterModelProps>;

    setProductToSearch: Action<ProductMasterModelProps, null | string>;
    setRequestSearchState: Action<ProductMasterModelProps, null | 'loading' | 'error' | 'success'>;
    setFilterOptions: Action<ProductMasterModelProps, Record<string, any> | null>;
    setProductsInPage: Action<ProductMasterModelProps, Record<string, any> | null>;
    setPage: Action<ProductMasterModelProps, number>;
    setRowsPerPage: Action<ProductMasterModelProps, number>;
}

const ProductMasterModel: ProductMasterModelProps = {
    filterOptions: null,
    requestSearchState: null,
    productsInPage: null,
    productToSearch: null,

    page: 1,
    rowsPerPage: 10,

    setFilterOptions: action((state, payload) => {
        state.filterOptions = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setProductsInPage: action((state, payload) => {
        state.productsInPage = payload;
    }),

    setPage: action((state, payload) => {
        state.page = payload;
    }),

    setRowsPerPage: action((state, payload) => {
        state.rowsPerPage = payload;
    }),

    setProductToSearch: action((state, payload) => {
        state.productToSearch = payload;
    }),

    requestProductsInPage: thunk(async (actions, payload: Record<string, any> | null, { getState }) => {
        try {
            actions.setRequestSearchState('loading')

            const { page, rowsPerPage, productsInPage, productToSearch } = getState()

            actions.setProductsInPage(null)

            const getFirms = payload?.filters?.getFirms || null
            const getSup = payload?.filters?.getSup || null
            const getCatRot = payload?.filters?.getCatRot || null
            const getItemName = payload?.filters?.getItemName || null

            const description = payload?.description || productToSearch || null

            const {order, orderBy} = payload?.filters?.order || {order: 'ASC', orderBy: 'ItemName'}

            let response = null

            if (productToSearch) {

                if (payload?.description) {
                    response = await axios.post('/search/search',
                        {
                            description,
                            LicTradNum: getSup?.length > 0 ? getSup : null,
                            FirmName: getFirms?.length > 0 ? getFirms : null,
                            categoria_rotacion: getCatRot?.length > 0 ? getCatRot : null,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );

                    actions.setPage(1)

                    response = {
                        ...response,
                        data: {
                            ...response.data,
                            data: {
                                allData: response.data['data'],
                                TotalPages: Math.ceil(response.data['data'].length / rowsPerPage),
                                TotalRecords: response.data['data'].length,
                                Datos: response.data['data'].slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage),
                                Page: 1,
                                isIASearch: true,
                            },
                        }
                    }
                } else {
                    response = {
                        status: 200,
                        data: {
                            error: false,
                            data: {
                                ...productsInPage,
                                Datos: productsInPage!.allData.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage),
                                Page: page,
                                isIASearch: true,
                            }
                        }
                    }
                }


            } else {
                response = await axios.post(`/product-master/articulos`,
                    {
                        firms: getFirms?.length > 0 ? getFirms : null,
                        lics: getSup?.length > 0 ? getSup : null,
                        cats: getCatRot?.length > 0 ? getCatRot : null,
                        itemName: getItemName?.length > 0 ? getItemName[0] : null,
                        page,
                        rowsPerPage,
                        order: {order, orderBy}
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
            }
            
            actions.setProductsInPage(response.data['data']);
            actions.setRequestSearchState( (response.status === 200 && response.data.error === false) ? 'success' : 'error')

            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching items in page:', error);
            return false;
        }
    }),

    requestAllPageData: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setRequestSearchState('loading')
            actions.setProductsInPage(null)
            actions.setFilterOptions(null)

            /* ------------------------ REQUEST PRODUCTS IN PAGE ------------------------ */
            const { page, rowsPerPage } = getState()
            const response_articulos = await axios.post(`/product-master/articulos`, { page, rowsPerPage, order: {order: 'ASC', orderBy: 'ItemName'} }, {
                headers: { 'Content-Type': 'application/json' }
            });

            /* ------------------------- REQUEST FILTER OPTIONS ------------------------- */
            const response_filter_options = await axios.get(`/product-master/filter-options`, { params: { 'page': 1, 'rowsPerPage': 500 } });


            actions.setProductsInPage(response_articulos.data['data']);
            actions.setFilterOptions(response_filter_options.data['data'])

            actions.setRequestSearchState(response_articulos.status === 200 && response_filter_options.status === 200 ? 'success' : 'error')

        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching items in page:', error);
            return false;
        }
    }),

    requestFilterOptions: thunk(async (actions) => {
        try {
            actions.setRequestSearchState('loading')
            actions.setFilterOptions(null)

            const response = await axios.get(`/product-master/filter-options`, { params: { 'page': 1, 'rowsPerPage': 500 } });

            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            actions.setFilterOptions(response.data['data']);

            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);
            return false;
        }
    }),

};

export default ProductMasterModel;