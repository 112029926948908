import { Card, CardMedia } from "@mui/material";
import { FC } from "react";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import SvgIcon from '@mui/material/SvgIcon';
import { Box } from "@mui/system";
interface CarouselSlideProps {
    readonly content: string
    readonly type: string
}

const ErrorImage: FC = () => <Box
    sx={{
        alignItems: 'center',
        borderRadius: 1,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    }}
>
    <SvgIcon fontSize='large'>
        <ImageNotSupportedIcon />
    </SvgIcon>
</Box>

const CarouselSlide : FC<CarouselSlideProps> = ({ content, type }) => {

    if(!content) return(<Box width='100%' height='100%'><ErrorImage /></Box>)

    return (
        <Card sx={{
            width: "100%",
            height: "100%",
            display: 'flex',
            alignSelf: 'center',
            justifySelf: 'center'
        }}>
            <CardMedia
                sx={{
                    margin: 'auto',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    backgroundImage: type === 'base64' ? `url(data:image/png;base64,${content})` : `url(${content})`,
                }}
            />
        </Card>
    );
};

export default CarouselSlide;
