import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';

import ShoppingBag03Icon from '../../icons/untitled-ui/duocolor/shopping-bag-03';
import Users03Icon from '../../icons/untitled-ui/duocolor/users-03';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}
export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(
    () => {
      return [
        {
          items: [
            {
              title: t('Maestra de productos'),
              path: '/maestra-productos',
              icon: (
                <SvgIcon fontSize="small">
                  <ShoppingBag03Icon />
                </SvgIcon>
              ),
            },
            {
              title: t('Demanda'),
              path: '/demanda',
              icon: (
                <SvgIcon fontSize="small">
                  <AutoGraphIcon />
                </SvgIcon>
              )
            },
            {
              title: t('Gestión de usuarios'),
              path: '/users',
              icon: (
                <SvgIcon fontSize="small">
                  <Users03Icon />
                </SvgIcon>
              )
            },
            {
              title: t('Bitácora'),
              path: '/bitacora',
              icon: (
                <SvgIcon fontSize="small">
                  <AutoGraphIcon />
                </SvgIcon>
              )
            },
          ]
        }
      ]
    },
    [t]
  );
};
