import { createStore, createTypedHooks } from 'easy-peasy';
import AuthModel from './authModel';
import SearchModel from './searchModel';
import UserModel from './userModel';
import productDetailModel from './productDetailModel';
import productMasterModel from './productMasterModel';
import demandModel from './demandModel';
import opportunityDetailModel from './opportunityDetailModel';
import binnacleModel from './binnacleModel';

const store = createStore({
    auth: AuthModel,
    search: SearchModel,
    user: UserModel,
    productDetail: productDetailModel,
    productMaster: productMasterModel,
    demand: demandModel,
    opportunityDetail: opportunityDetailModel,
    binnacle: binnacleModel,
});

export const { useStoreActions, useStoreState } = createTypedHooks();
export default store;
